import axios from "axios";

const payuCreatePaymentService = async (url, req) => {
  try {
    const headers = {
      // "Access-Control-Allow-Origin": "http://localhost:3000/",
      "Content-Type": "multipart/form-data",
    };
    const data = await axios.post(url, req, {
      headers: headers,
    });
    if (data) {
      return data?.data;
    }
  } catch (error) {
    console.error("payU create payment error", error);
  }
};

export { payuCreatePaymentService };
