import React from "react";

/**
 *
 * @param {*} iType
 * @param {*} iClass
 * @param {*} iId
 * @param {*} iPlaceholder
 * @param {*} iName 
 * @param {*} iLabel 
 * @param {*} iValue
 * @param {*} iDisabled
 * @param {*} iChecked
 * @param {*} iChangeHandler
 * @param {*} iErrors
 * @param {*} iMin
 * @param {*} iMax
 * @returns
 */
const InputComponent = ({
  iType,
  iClass,
  iId,
  iPlaceholder,
  iName,
  iValue,
  iChangeHandler,
  iDisabled,
  iChecked,
  iErrors,
  iLabel,
  iMin,
  iMax,
  iMaxL
}) => {
  const showError = (errorIn) => {
    const errorFound = iErrors?.find((err) => err?.errorIn === errorIn);
    return errorFound?.text || null;
  };
  return (
    <>
      {iLabel && <label className="form-check-label">{iLabel}</label>}
      <input
        type={iType}
        className={iClass}
        id={iId}
        placeholder={iPlaceholder}
        name={iName}
        value={iValue}
        onChange={iChangeHandler}
        disabled={iDisabled || false}
        checked={iChecked || false}
        min={iMin}
        max={iMax}
        maxLength={iMaxL}
        autocomplete="off"
      />

      {iErrors?.length > 0 && (
        <small className="text-danger">{showError(iName)}</small>
      )}
    </>
  );
};

export default InputComponent;