import React from "react";

/**
 * @param {*} riValue
 * @param {*} radioType  paymentType | slotArray
 * @returns
 */
const RadioInputComponent = ({
  slotObj,
  radioType,
  riLabel,
  riName,
  riChangeHandler,
  riValue,
  riIsChecked,
}) => {
  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        name={riName}
        // id="inlineRadio1"
        value={riValue}
        onChange={riChangeHandler}
        disabled={slotObj?.available_seats === 0}
        checked={riIsChecked}
      />
      {radioType === "paymentType" && (
        <label className="form-check-label" htmlFor="inlineRadio1">
          {riLabel}
        </label>
      )}
      {radioType === "slotArray" && (
        <label className="form-check-label" htmlFor="inlineRadio1">
          {slotObj?.start_time?.slice(0, 5)} - {slotObj?.end_time?.slice(0, 5)}{" "}
          <br />
          <small
            className={`text-${
              slotObj?.available_seats === 0 ? "danger" : "success"
            }`}
          >
            (Available seats : {slotObj?.available_seats})
            {slotObj?.description?
              (<i style={{"color":"red"}}> {slotObj?.description}</i>)
            : ""
            }
          </small>
        </label>
      )}
    </div>
  );
};

export default RadioInputComponent;
