export const searchParentURL = 'search-parent';
export const numberDetailsURL = "number-details";
export const slotDetailsURL = "slot-details";
export const cashDataURL = "cash-data";
export const createSlotURL = "create-slots";
export const deleteSlotURL = "delete-slot";
export const adminLoginURL = "admin-login"; 
export const getDiscountURL = "discount-membership";
export const deleteDiscountURL = "delete-discount-membership";
export const createDiscountURL = "create-discount-membership";
export const sendWhatsappOtp = "send-whatsapp-otp";
export const verifyOtp = "verify-otp";
export const paymentURL = "payment";


// Payment 
export const payuCreatePaymentURL = "https://secure.payu.in/_payment";


