import "./App.css";
import logo2 from "./assets/images/tumble_logo.png";
import { useState ,useEffect} from "react";
import InputComponent from "./Components/InputComponent";
import moment from "moment/moment";
import Swal from "sweetalert2";
import {
  searchParentService,
  numberDetailsService,
  slotDetailsService,
} from "./api/_api";
import { searchParentURL, numberDetailsURL, slotDetailsURL } from "./api/config";
import DropdownListComponent from "./Components/DropdownListComponent";
import RadioInputComponent from "./Components/RadioInputComponent";

function App() {
 
  

  return (
   <></>
  );
}

export default App;
