import React from "react";
import { useState } from "react";
import { payuCreatePaymentURL } from "../api/config";
import { payuCreatePaymentService } from "../api/_payment";

const Payment = () => {
  const [payment, setPayment] = useState({
    key: "JqevLFAZ",
    txnid: "hjsdgsuahd1676",
    amount: "10",
    firstname: "test payu",
    email: "test8989@yopmail.com",
    phone: "9876543210",
    productinfo: "apple",
    surl: "https://apiplayground-response.herokuapp.com/",
    furl: "https://apiplayground-response.herokuapp.com/",
    hash: "eyEBEvOQyc",
  });

  const onPaymentClick = () => {
    payuCreatePaymentService(payuCreatePaymentURL, payment)
      .then((res) => console.log("payment", res))
      .catch((err) => console.log("payment err", err));
  };

  return (
    <div>
      <button onClick={onPaymentClick}>Payment</button>
    </div>
  );
};

export default Payment;
