import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  createDiscountURL,
  deleteDiscountURL,
  getDiscountURL,
} from "../api/config";
import {
  createDiscountService,
  deleteDiscountService,
  getDiscountService,
} from "../api/_api";

const DiscountComponent = ({ nextSteps }) => {
  const [tableData, setTableData] = useState([]);

  const onAddMoreClick = () => {
    let option = {
      // id: tableData?.length + 1,
      number: "",
      discount_type: "",
      hour_available: 0,
      hour_count: 0,
      hour_used: 0,
      percentage_count: 0,
    };
    setTableData((prevArray) => [...prevArray, option]);
  };
  const onRemoveClick = (event, id, rowIndex) => {
    event.preventDefault();
    if (id == undefined) {
      setTableData((prevArray) =>
        prevArray.filter((item, index) => index !== rowIndex)
      );
    } else {
      const payload = {
        id: id,
      };

      deleteDiscountService(deleteDiscountURL, payload)
        .then((res) => {
          if (res) {
            // setTableData(res?.data);
            Swal.fire({
              icon: "success",
              title: "",
              text: res?.message,
            });
            
            getDiscountService(getDiscountURL)
              .then((res) => {
                if (res) {
                  setTableData(res?.data[0]);
                 
                }
              })
              .catch((err) => console.log("slotDetailsService", err));
          }
        })
        .catch((err) => console.log("slotDetailsService", err));
    }
  };
  const onSubmitClick = (event) => {
    event.preventDefault();
    const payload = {
      data: tableData,
    };
    
    createDiscountService(createDiscountURL, payload)
      .then((res) => {
        if (res) {
          // setTableData(res?.data);
          Swal.fire({
            icon: "success",
            title: "",
            text: res?.message,
          });
         
          getDiscountService(getDiscountURL)
            .then((res) => {
              if (res) {
                setTableData(res?.data[0]);
               
              }
            })
            .catch((err) => console.log("slotDetailsService", err));
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  };

  const onFieldChange = (event, slotId) => {
    let name = event.target.name;
    let value = event.target.value;
   

    const itemIndex = tableData.findIndex((index) => index === slotId);


    // if (name== "Discount") {
    //     const updatedItem = {
    //       ...tableData[slotId],
    //       hour_count:
    //         0
    //     };
    //     const updatedItems = [...tableData];
    //     updatedItems[slotId] = updatedItem;
    //     setTableData(updatedItems);
    // }
    // if (name=="Membership"){
    //     const updatedItem = {
    //       ...tableData[slotId],
    //       percentage_count: 0,
    //     };
    //     const updatedItems = [...tableData];
    //     updatedItems[slotId] = updatedItem;
    //     setTableData(updatedItems);
    // }

    // if (name!="Discount" || name!="Membership"){
    const updatedItem = {
      ...tableData[slotId],
      [name]:
        name == "percentage_count" || name == "hour_count"
          ? parseInt(value)
          : value,
    };

    const updatedItems = [...tableData];
    updatedItems[slotId] = updatedItem;
    setTableData(updatedItems);
  };

  //   };

  useEffect(() => {
    getDiscountService(getDiscountURL)
      .then((res) => {
        if (res) {
          setTableData(res?.data[0]);
         
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  }, []);

  return (
    <div className="container">
      <button
        type="button"
        class="btn btn-info m-2"
        onClick={() => nextSteps(1)}
      >
        {""}Slots
      </button>
      <button
        type="button"
        class="btn btn-info m-2"
        onClick={() => onAddMoreClick()}
      >
        Add
      </button>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Ph. No.</th>
            <th>Type</th>
            <th>Discount</th>
            <th>Membership</th>
            {/* <th>Price</th> */}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="text"
                  value={row.number}
                  onChange={(event) => onFieldChange(event, rowIndex)}
                  name="number"
                />
              </td>
              <td>
                <select
                  value={row?.discount_type}
                  onChange={(event) => onFieldChange(event, rowIndex)}
                  name="discount_type"
                >
                  <option value="Discount">Discount</option>
                  <option value="Membership">Membership</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={
                    row?.discount_type == "Discount" ? row.percentage_count : 0
                  }
                  onChange={(event) => onFieldChange(event, rowIndex)}
                  name="percentage_count"
                  disabled={row?.discount_type != "Discount" ? true : false}
                />{" "}
                %
              </td>
              <td>
                <input
                  type="number"
                  value={
                    row?.discount_type == "Membership" ? row.hour_available : 0
                  }
                  onChange={(event) => onFieldChange(event, rowIndex)}
                  name="hour_available"
                  disabled={row?.discount_type != "Membership" ? true : false}
                />{" "}
                hours
              </td>

              <td>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={(event) => onRemoveClick(event, row?.id, rowIndex)}
                >
                  -
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={(event) => onSubmitClick(event)}
        className="btn shadow mt-2 btn-info text-white btn-sm"
      >
        Save
      </button>
    </div>
  );
};

export default DiscountComponent;
