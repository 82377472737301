import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { createSlotURL, deleteSlotURL, slotDetailsURL } from "../api/config";
import {
  createSlotsService,
  slotDetailsService,
  deleteSlotService,
} from "../api/_api";

const DynamicInputFields = ({ seletedDate, nextSteps }) => {
  const [tableData, setTableData] = useState([]);

  const onAddMoreClick = () => {
    let option = {
      // id: tableData?.length + 1,
      slot_date: seletedDate?.split("-")?.join("/"),
      start_time: "",
      end_time: "",
      available_seats: "",
      price: "",
    };
    setTableData((prevArray) => [...prevArray, option]);
  };
  const onRemoveClick = (event, id, rowIndex) => {
    event.preventDefault();
    if (id == undefined) {
      setTableData((prevArray) =>
        prevArray.filter((item, index) => index !== rowIndex)
      );
    } else {
      const payload = {
        id: id,
      };
     
      deleteSlotService(deleteSlotURL, payload)
        .then((res) => {
          if (res) {
            // setTableData(res?.data);
            Swal.fire({
              icon: "success",
              title: "",
              text: res?.message,
            });
            
            slotDetailsService(slotDetailsURL, {
              date: seletedDate?.split("-")?.join("/"),
              isAdminSlots: 1,
            })
              .then((res) => {
                if (res) {
                  setTableData(res?.data);
                }
              })
              .catch((err) => console.log("slotDetailsService", err));
          }
        })
        .catch((err) => console.log("slotDetailsService", err));
    }
  };
 
  const onFieldChange = (event, slotId) => {
    let name = event.target.name;
    let value = event.target.value;
   

    const itemIndex = tableData.findIndex((index) => index === slotId);
   
    const updatedItem = {
      ...tableData[slotId],
      [name]:
        name == "available_seats" || name == "price" ? parseInt(value) : value,
    };

    const updatedItems = [...tableData];
    updatedItems[slotId] = updatedItem;
    setTableData(updatedItems);
  };

  const onSubmitClick = (event) => {
    event.preventDefault();
    const payload = {
      data: tableData,
    };
  
    createSlotsService(createSlotURL, payload)
      .then((res) => {
        if (res) {
          // setTableData(res?.data);
          Swal.fire({
            icon: "success",
            title: "",
            text: res?.message,
          });
       
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  };

  useEffect(() => {
    slotDetailsService(slotDetailsURL, {
      date: seletedDate?.split("-")?.join("/"),
      isAdminSlots: 1,
    })
      .then((res) => {
        if (res) {
          setTableData(res?.data);
        }
      })
      .catch((err) => console.log("slotDetailsService", err));
  }, [seletedDate]);

  return (
    <div className="container">
      {!seletedDate ? null : (
        <>
          {" "}
          <button
            type="button"
            class="btn shadow btn-info text-white btn-sm m-2"
            onClick={() => onAddMoreClick()}
          >
            Add Slots
          </button>
        </>
      )}
      <button
        type="button"
        class="btn shadow btn-info text-white btn-sm"
        onClick={() => nextSteps(2)}
      >
        Discounts
      </button>
      {!seletedDate ? null : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Slots</th>
              <th>Price</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="time"
                    value={row.start_time}
                    onChange={(event) => onFieldChange(event, rowIndex)}
                    name="start_time"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={row.end_time}
                    onChange={(event) => onFieldChange(event, rowIndex)}
                    name="end_time"
                    // disabled="true"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.available_seats}
                    onChange={(event) => onFieldChange(event, rowIndex)}
                    name="available_seats"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.price}
                    onChange={(event) => onFieldChange(event, rowIndex)}
                    name="price"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.description}
                    onChange={(event) => onFieldChange(event, rowIndex)}
                    name="description"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={(event) => onRemoveClick(event, row?.id, rowIndex)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <button
        onClick={() => nextSteps(0)}
        className="btn mx-2 shadow btn-warning btn-sm text-light"
      >
        Previous
      </button>
      <button
        onClick={(event) => onSubmitClick(event)}
        className="btn shadow  btn-info text-white btn-sm"
      >
        Save
      </button>
    </div>
  );
};

export default DynamicInputFields;
