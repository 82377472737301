import React from "react";

/**
 *
 * @param {*} ulClass
 * @param {*} liClass
 * @param {*} listArray
 * @param {*} itemKey
 * @returns
 */
const DropdownListComponent = ({
  ulClass,
  liClass,
  listArray,
  itemKey,
  itemObjectTakerFn,
}) => {
  return (
    <ul className={ulClass || ""}>
      {listArray?.map((liItem, i) => (
        <li
          className={liClass || ""}
          onClick={() => itemObjectTakerFn(liItem)}
          key={i}
        >
          {liItem?.[itemKey]}
        </li>
      ))}
    </ul>
  );
};

export default DropdownListComponent;
